import React from 'react'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { MDXProvider } from "@mdx-js/react"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import SSRProvider from 'react-bootstrap/SSRProvider'

import AlumniQuotes from '../components/alumni/alumniQuotes'
import AlumniHeader from '../components/alumni/alumniHeader'
import Footer from '../components/footer'
import GdprPanel from "../components/gdpr"
import Seo from '../components/seo'
import Navigation from '../components/navigation'

import '../styles/alumni.scss'
import NewsletterSignup from '../components/newsletterSignUp'

export default function AlumniStoriesPage({ data }) {

    const post = data.mdx

    const shortcodes = {
        Container,
        Row,
        Col,
        AlumniQuotes,
    }

    return (      
        <>  
        <SSRProvider>
            <Navigation />
            <AlumniHeader headline={post.frontmatter.headline} heroBackground={post.frontmatter.heroBackground} heroMobileBackground={post.frontmatter.heroMobileBackground} subHeadline={post.frontmatter.subheadline}  />
            <div id="body" className="alumni">
                <Container fluid="lg">
                    <Row className="justify-content-center py-4 my-4">
                        <Col xl={8} lg={9} md={10} xs={12} className="stories">
                            <MDXProvider components={shortcodes}>
                                <MDXRenderer>{post.body}</MDXRenderer>
                            </MDXProvider>      
                        </Col>
                    </Row> 
                </Container>
            </div>     
            <Footer />
            <GdprPanel />
            <NewsletterSignup />
        </SSRProvider>
        </>
    )

}

export const Head = ({data}) => (
    <Seo 
        title={data.mdx.frontmatter.metadata.Title} 
        description={data.mdx.frontmatter.metadata.Description} 
        pathname={data.mdx.frontmatter.metadata.Link}
        ogImage={data.mdx.frontmatter.metadata.ogImage}
        cardImage={data.mdx.frontmatter.metadata.cardImage} 
    />
)

export const query = graphql`
    query($slug: String!) {
        mdx(slug: { eq: $slug }) {
            frontmatter {
                heroBackground
                heroMobileBackground
                subheadline
                headline
                title
                headerType
                metadata {
                    Description
                    Keywords
                    Link
                    Title
                    ogImage
                    cardImage
                }
                menuType
                subNav {
                    text
                    url
                }
            }
            body
        }
    }   
`