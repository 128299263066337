import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './alumniHeader.scss';

function AlumniHeader({ headline, heroBackground, heroMobileBackground, subHeadline  }) {

    const { width } = useWindowSize();

    return (
        <div id="mu-hero-alumni-stories" style={{ "--img": width > 1199 ? heroBackground : heroMobileBackground }}>
            <Container fluid="lg">
                <Row className="align-items-center justify-content-center">
                    <Col xl={10} xs={11}  className="ps-xl-0 my-md-5 my-4 text-center">
                        <h1 className="mu-hero-headline">{ headline }</h1>
                        <h2 className="mu-hero-subheadline">{ subHeadline }</h2>
                    </Col>
                </Row>
            </Container>
        </div>
    )        
    
}

function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
      width: undefined
    });
  
    useEffect(() => {
      // only execute all the code below in client side
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth
        });
      }
      
      // Add event listener
      window.addEventListener("resize", handleResize);
       
      // Call handler right away so state gets updated with initial window size
      handleResize();
      
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
}


export default AlumniHeader